import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["severity"];

  connect() {
    this.updateDescription();
    this.updateProbabilityHint();
  }

  updateProbabilityHint() {
    const value = document.querySelector(`input[name="settings[probability_of_residual_risk]"]:checked`)?.value;
    if (!value) return;
    const hint = document.querySelector(`.settings_probability_of_residual_risk .form-text`);
    hint.innerHTML = document.querySelector(`[data-probability="${value}"]`).innerHTML;
  }

  updateDescription() {
    const severity = this.severityTarget;
    const hint = severity.parentElement.querySelector(".form-text");
    const description = document.querySelector(`[data-severity="${severity.value}"]`);

    hint.innerHTML = description.innerHTML;
  }
}
