import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["severity"];

  connect() {
    this.updateDescription();
  }

  updateDescription() {
    const severity = this.severityTarget;
    const hint = severity.parentElement.querySelector(".form-text");
    const description = document.querySelector(`[data-severity="${severity.value}"]`);

    hint.innerHTML = description.innerHTML;
  }
}
