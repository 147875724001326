import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  async translate({ params }) {
    const url = new URL("/translate", document.location);
    url.searchParams.append("text", document.getElementById(params.source).value);

    const response = await fetch(url.href);
    if (response.ok) {
      document.getElementById(params.target).value = await response.text();
    }
  }
}
